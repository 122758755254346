import './App.css';
import UserForm from './components/UserForm'
import 'bootstrap/dist/css/bootstrap.css';
import WebFont from "webfontloader";
  WebFont.load({google: {families: ["Roboto:300,400,500"]}});

function App() {


  return (
    <div className="App">
        <UserForm />

    </div>
  );
}

export default App;
