import React, { Component } from 'react';
import FormUserDetails from './FormUserDetails';

const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{1,2})$/i);
const validWebsiteRegex =/^(https:\/\/www\.|http:\/\/www\.|www\.)[a-zA-Z0-9\-_$]+\.[a-zA-Z]{1,2}$/g;
const validLinkInRegex = RegExp(/http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/gi);
const validTwitterRegex= RegExp(/^(?:https?:\/\/)?(?:www\.)?twitter\.com\/(#!\/)?[a-zA-Z0-9_]+$/i);
const validFullNameRegex = RegExp(/(^[A-Za-z]{3,16})([ ]{0,1})([A-Za-z]{3,16})?([ ]{0,1})?([A-Za-z]{3,16})?([ ]{0,1})?([A-Za-z]{3,16})/)
const validFullPosition = RegExp(/[a-zA-Z][^<>%$!@#^&*)(_=+-./;`~"0123456789]{3,25}$/);

export class UserForm extends Component {

  state = {
    step: 1,
    fullName: '',
    fullPosition: '',
    email: '',
    company: '',
    website: '',
    address: '',
    linkedIn: '',
    twitter: '',
    fullNameErrorMessage: '',
    fullPositionErrorMessage: '',
    emailErrorMessage: '',
    companyErrorMessage: '',
    websiteErrorMessage: '',
    addressErrorMessage: '',
    linkedInErrorMessage: '',
    twitterErrorMessage: '',
    errorMessageErrorMessage: '',

  }

  //Handle fields change
  handleChange = input => e => {
    this.setState({[input]: e.target.value});

    if(input === 'email'){
      if(this.state.email  && this.state.email.match(validEmailRegex)){
        this.setState({emailErrorMessage: ""})
      }
     else {
        this.setState({emailErrorMessage : "The Email is not valid!"});
    }
  }
    if(input === 'website'){
      if(this.state.website  && this.state.website.match(validWebsiteRegex)){
          this.setState({websiteErrorMessage: ""})
      }
     else {
      this.setState({websiteErrorMessage: "The Website is not valid!"});
    }
  }
    if(input === 'linkedIn'){
      if(this.state.linkedIn  && this.state.linkedIn.match(validLinkInRegex)){
          this.setState({linkedInErrorMessage: ""})
      }
     else {
      this.setState({linkedInErrorMessage: "The Website is not valid!"});
    }
  }
    if(input === 'twitter'){
      if(this.state.twitter  && this.state.twitter.match(validTwitterRegex)){
          this.setState({twitterErrorMessage: ""})
      }
     else {
      this.setState({twitterErrorMessage: "The Website is not valid!"});
    }
  }
    if(input === 'fullName'){
      if(this.state.fullName  && this.state.fullName.match(validFullNameRegex)){
          this.setState({fullNameErrorMessage: ""})
      }
     else {
      this.setState({fullNameErrorMessage: "The name is not valid!"});
    }
  }
    if(input === 'fullPosition'){
      if(this.state.fullPosition  && this.state.fullPosition.match(validFullPosition)){
          this.setState({fullPositionErrorMessage: ""})
      }
     else {
      this.setState({fullPositionErrorMessage: "The position is not valid!"});
    }
  }
}
  render() {
    const { step } = this.state;
    const { fullName, fullPosition, email, company, website, address, linkedIn,twitter,
    fullNameErrorMessage,errorMessageErrorMessage,twitterErrorMessage,fullPositionErrorMessage,linkedInErrorMessage,addressErrorMessage,websiteErrorMessage,companyErrorMessage, emailErrorMessage} = this.state;
    const values = { fullName, fullPosition, email, company, website, address, linkedIn,twitter,
    fullNameErrorMessage,errorMessageErrorMessage,twitterErrorMessage,fullPositionErrorMessage,linkedInErrorMessage,addressErrorMessage,websiteErrorMessage,companyErrorMessage, emailErrorMessage}

      switch (step) {
        case 1:
          return (
            <FormUserDetails
              nextStep={this.nextStep}
              handleChange={this.handleChange}
              values={values}
            />
          );

      }
  }
}

export default UserForm
