import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TextField from '@material-ui/core/TextField';
import { Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';

import { colourOptions } from './data';
import chroma from 'chroma-js';
import ReactTooltip from 'react-tooltip';

import addressLogo from './../assets/Address.png';
import emailLogo from './../assets/Email.png';
import linkedInLogo from './../assets/LinkedInBig.png';
import twitterLogo from './../assets/TwitterBig.png';
import websiteLogo from './../assets/Website.png';
import uploadPhoto from './../assets/UploadPhoto.png';
import emailPhoto from './../assets/EmailPhoto.png';


export class  FormUserDetails extends Component {

  state = {
    font: '',
    culoare: '',
    profileImg: uploadPhoto,
    uploadImg:'',
    FullNameError: '',
    missingPhoto:'',
  }

onChangeColor = (culoare) => {
  this.setState({ culoare });
}

onChangeFont = (font) => {
this.setState({ font });
}

  continue = e => {
    e.preventDefault();
    this.props.nextStep();

  }

  copyTable = () => {

      if(!this.state.uploadImg){
        this.setState({ missingPhoto: 'You forgot to add a logo picture!'})
      }
  const elTable = document.querySelector('table');

  let range, sel;

  if (document.createRange && window.getSelection) {

    range = document.createRange();
    sel = window.getSelection();
    sel.removeAllRanges();

    try {
      range.selectNodeContents(elTable);
      sel.addRange(range);
    } catch (e) {
      range.selectNode(elTable);
      sel.addRange(range);
    }

    document.execCommand('copy');
  }

  sel.removeAllRanges();

  console.log('Element Copied! Paste it in a file')
  }

  imageHandler = (e) => {
      const reader = new FileReader();
      reader.onload = () =>{
        if(reader.readyState === 2){
          this.setState({profileImg: reader.result , uploadImg: reader.result})
        }
      }
      reader.readAsDataURL(e.target.files[0])
    };
  render() {

    const options = [
  { value: 'georgia  ', label: 'Georgia  ' },
  { value: 'helvetica', label: 'Helvetica' },
  { value: 'times new roman', label: 'Times New Roman' }
]

    const dot = (color = '#ccc') => ({
      alignItems: 'center',
      display: 'flex',

      ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
      },
    });

    const colourStyles = {
      control: styles => ({ ...styles, backgroundColor: 'white' }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color);
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? data.color
            : isFocused
            ? color.alpha(0.1).css()
            : null,
          color: isDisabled
            ? '#ccc'
            : isSelected
            ? chroma.contrast(color, 'white') > 2
              ? 'white'
              : 'black'
            : data.color,
          cursor: isDisabled ? 'not-allowed' : 'default',

          ':active': {
            ...styles[':active'],
            backgroundColor:
              !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
          },
        };
      },
      input: styles => ({ ...styles, ...dot() }),
      placeholder: styles => ({ ...styles, ...dot() }),
      singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
    };
    const styleObj = {
      color: this.state.culoare.color,
      fontFamily: this.state.font.value
    }
    const {uploadImg}= this.state;
    const {profileImg}= this.state;
    const {missingPhoto}= this.state;
    const { values, handleChange } = this.props;
    const { values: { fullName, fullPosition, email, company, website, address, linkedIn, twitter} } = this.props;

    return (
        <MuiThemeProvider>
      <div className="FormUserDetails">

      <div className="app-title">
        <h1> Signature Generator</h1>
        <h5> Build your new e-mail signature</h5>
      </div>


        <Row>
          <Col className="get_in_touch" sm={10}>
            <Row>
              <Col style={{paddingBottom:'2.5rem'}}>

                  <div className= "sub-title">
                    <h4>Signature Details</h4>
                  </div>
                  <Row className="align-photo">
                    <Col className="upload-section-picture-section" sm={4}>
                      <Col className="upload-section-picture" >
                              <img style={{width:'83%'}} src={profileImg} alt="" id="img" className="img" />
                      </Col>

                      <Col className="upload-section-button"  >
                            <input type="file" accept="image/*" name="image-upload" id="input" onChange={this.imageHandler} />
                              <label className="image-upload" htmlFor="input">
                                  Upload Logo
                              </label>
                      </Col>
                    </Col>
                    <Col sm={8}>
                      <Col className="form-font">
                          <strong>Font</strong>
                             <Select
                             closeMenuOnSelect={false}
                              options={options}
                              onChange={this.onChangeFont}
                             />
                      </Col>
                      <Col className="form-Color">
                      <strong>Font Color</strong>
                      <Select
                        onChange={this.onChangeColor}
                        defaultValue={this.onChangeColor}
                        label="Single select"
                        options={colourOptions}
                        styles={colourStyles}
                      />

                      </Col>
                    </Col>
                    </Row>
                          <Row>
                            <Col>
                            <Col className="form-column">
                              <TextField
                              placeholder="Enter your Full Name"
                              label="Full Name"
                              onChange={handleChange('fullName')}
                              defaultValue={values.fullName}
                              margin="normal"
                              />
                              </Col>
                            { values.fullName ? (<Col className="ErrorMessage" >{values.fullNameErrorMessage}</Col> ) : null }

                            </Col >
                            <Col>
                            <Col className="form-column">
                              <TextField
                              placeholder="Enter your Full Position"
                              label="Full Position"
                              onChange={handleChange('fullPosition')}
                              defaultValue={values.fullPosition}
                              margin="normal"

                              />

                              </Col>
                            { values.fullPosition ? (<Col className="ErrorMessage" >{values.fullPositionErrorMessage}</Col> ) : null }

                            </Col>
                          </Row>

                          <Row>
                            <Col>
                             <Col className="form-column">
                              <TextField
                                placeholder="Enter your Email"
                                label="Email"
                                onChange={handleChange('email')}
                                defaultValue={values.email}
                                margin="normal"

                              />
                                </Col>
                              { values.email ? (<Col className="ErrorMessage" >{values.emailErrorMessage}</Col> ) : null }

                            </Col>
                            <Col>
                            <Col className="form-column">
                              <TextField
                                placeholder="Enter your Company"
                                label="Company"
                                onChange={handleChange('company')}
                                defaultValue={values.company}
                                margin="normal"

                              />
                            </Col>
                            </Col>
                          </Row>

                          <Row>
                          <Col>
                            <Col className="form-column">
                              <TextField
                                placeholder="Enter your Website"
                                label="Website"
                                onChange={handleChange('website')}
                                defaultValue={values.website}
                                margin="normal"

                              />
                              </Col>
                              { values.website ? (<Col className="ErrorMessage" >{values.websiteErrorMessage}</Col> ) : null }
                            </Col>
                            <Col>
                              <Col className="form-column">
                                <TextField
                                  placeholder="Enter your Address"
                                  label="Address"
                                  onChange={handleChange('address')}
                                  defaultValue={values.address}
                                  margin="normal"

                                />
                                </Col>
                            </Col>
                          </Row>

                          <Row>
                          <Col>
                            <Col className="form-column">
                              <TextField
                                placeholder="Enter your LinkedIn"
                                label="LinkedIn"
                                onChange={handleChange('linkedIn')}
                                defaultValue={values.linkedIn}
                                margin="normal"
                                />
                              </Col>
                                  { values.linkedIn ? (<Col className="ErrorMessage" >{values.linkedInErrorMessage}</Col> ) : null }
                            </Col>
                          <Col>
                            <Col className="form-column">
                              <TextField
                                placeholder="Enter your Twitter"
                                label="Twitter"
                                onChange={handleChange('twitter')}
                                defaultValue={values.twitter}
                                margin="normal"

                              />
                              </Col>
                            { values.twitter ? (<Col className="ErrorMessage" >{values.twitterErrorMessage}</Col> ) : null }

                            </Col>
                          </Row>

              </Col>
          <Col className="get_in_touch_email">


                <div className= "sub-title">
                  <h4>Signature Preview</h4>
                </div>
                <Container>
                <div className="email-background" sm={10}>
                  <div className="email-title">
                    <span style={{fontWeight: "bold"}}>The New Signature</span>
                  </div>
                <Col className="icon-email-name">
                  <Col className="email-icon" sm={1}>
                     <img  src={emailPhoto} alt='' />
                  </Col>
                  <Col className="email-details" sm={6}>
                    <span> Miruna.Nechifor@digiscope.eu</span>
                    <span> 06/15/1993 10:15: AM</span>
                  </Col>
                </Col>
                <div className="to-title">
                  <span> To:</span> <span className="to-who"> Robert.Jianu@digiscope.eu </span>
                </div>
                  <div className="email-message">
                      <p> Here would be your e-mail content which will be followed by your stunning new signature.
                        <br/>
                        <br/>
                        Do you like your new signature? Copy and use it now.
                      </p>
                  </div>

                <div style={styleObj} className="signature-container" id="table">
                  <table>
                    <tbody>
                      <tr>
                       {uploadImg ? ( <td className="imagine-setup">
                          <table>
                            <tbody>
                              <tr >
                                <img src={uploadImg} alt ="" width="120" height="120"/>
                              </tr>
                              <tr>
                              {values.linkedIn || values.twitter ? ( <td className="social-links">
                                  <span className="linkedIn-icon">
                                    {values.linkedIn ? ( <a href={values.linkedIn}>
                                     <img width="24" src={linkedInLogo} style={{verticalAlign : 'bottom'}} alt='' /></a> ) :  null }
                                  </span>
                                  <span className="twitter-icon">
                                    { values.twitter ? ( <a href={values.twitter}>
                                     <img width="24" src={twitterLogo} style={{verticalAlign : 'bottom'}} alt='' /></a> ) : null }
                                  </span>
                                </td>
                              ) : null }
                              </tr>
                            </tbody>
                          </table>
                        </td>
                         ) : null }
                      <td className="table-content">
                          <table >
                            <tbody>
                              <tr>
                              {values.fullName ? ( <td style={styleObj} className="name">
                                  <span>
                                    {fullName}
                                  </span>
                                </td>
                              ): null }
                              </tr>
                              <tr>
                                { values.fullPosition || values.company ? (<td style={styleObj} className="full-position">
                                  <span>
                                    {fullPosition}
                                  </span>
                                  {values.company ? (<span className="dot">
                                    ▪︎
                                  </span>) : null }
                                  <span style={styleObj} className="company">
                                    {company}
                                  </span>
                                </td>
                              ) : null}
                              </tr>
                              <tr>
                             {values.email ? (  <td  className="email">
                                  <span>
                                    <img width="20" src={emailLogo} style={{verticalAlign : 'bottom'}} alt='' />
                                  </span>
                                  <span style={styleObj} className="email-text">
                                    {email}
                                  </span>
                                </td>
                              ) : null}
                              </tr>
                              <tr>
                              {values.address ? (  <td  className="address">
                                  <span>
                                    <img width="20" src={addressLogo} aria-hidden="true" style={{verticalAlign : 'bottom'}} alt='' />
                                  </span>
                                  <span style={styleObj} className="address-text">
                                    {address}
                                  </span>
                                </td>
                              ) : null}
                              </tr>
                              <tr>
                              {values.website ? (  <td className="website">
                                  <span>
                                     <img width="20" src={websiteLogo} style={{verticalAlign : 'bottom'}} alt='' />
                                  </span>
                                  <span style={styleObj} className="website-text">
                                    {website}
                                  </span>
                                </td>
                              ) : null }
                              </tr>

                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

      </div>
                 </Container>
                  <Row className="buttonAlign" sm={5}>
                      <button  data-tip data-for='happyFace' className="RaisedButton1" onClick={this.copyTable}>
                        Copy Signature
                      </button>
                      {!uploadImg ? (<ReactTooltip className="error-picture" id='happyFace' type='error' effect='solid' place='left' >
                        <span>You forgot logo picture!</span>
                      </ReactTooltip>
                      ) : null }
                  </Row>

          </Col>
            </Row>
      </Col>
      </Row>
  </div>
  </MuiThemeProvider>
  );

  }

}

export default FormUserDetails
